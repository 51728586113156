<template>
  <div>
    <CRow>
      <CCol xs="12" lg="12">
        <CCard>
          <CCardHeader>
            <div class="row">
              <CCol sm="6" class="align-self-center">
                <strong>{{ $lang.calendar.crud.create }}</strong>
              </CCol>
            </div>
          </CCardHeader>
          <CCardBody>
            <CAlert :color="messageColor" closeButton :show.sync="dismissCountDown" fade>
              {{ err_msg }}
            </CAlert>
            <CForm @submit.prevent="onProfileSubmit">
              <CRow>
                <CCol md="6">
                  <div class="form-group">
                    <label class="form__label">{{ $lang.calendar.form.startDate }}
                      <required_span/>
                    </label>
                    <input type="date" :placeholder="$lang.calendar.form.startDate" class="form-control"
                           v-model="calendar.startDate"/>
                    <small class="error" v-if="$v.calendar.$error && !$v.calendar.startDate.required">{{
                        $lang.calendar.validation.required.startDate
                      }}</small>
                  </div>
                </CCol>
                <CCol md="6">
                  <div class="form-group">
                    <label class="form__label">{{ $lang.calendar.form.endDate }}
                      <required_span/>
                    </label>
                    <input type="date" :placeholder="$lang.calendar.form.endDate" class="form-control"
                           v-model="calendar.endDate"/>
                    <small class="error" v-if="$v.calendar.$error && !$v.calendar.endDate.required">{{
                        $lang.calendar.validation.required.endDate
                      }}</small>
                  </div>
                </CCol>
                <CCol md="6">
                  <div class="form-group">
                    <label class="form__label">{{ $lang.calendar.form.startTime }}
                      <required_span/>
                    </label>
                    <input type="time" :placeholder="$lang.calendar.form.startTime" class="form-control"
                           v-model="calendar.startTime"/>
                    <small class="error" v-if="$v.calendar.$error && !$v.calendar.startTime.required">{{
                        $lang.calendar.validation.required.startTime
                      }}</small>
                  </div>
                </CCol>
                <CCol md="6">
                  <div class="form-group">
                    <label class="form__label">{{ $lang.calendar.form.endTime }}
                      <required_span/>
                    </label>
                    <input type="time" :placeholder="$lang.calendar.form.endTime" class="form-control"
                           v-model="calendar.endTime"/>
                    <small class="error" v-if="$v.calendar.$error && !$v.calendar.endTime.required">{{
                        $lang.calendar.validation.required.endTime
                      }}</small>
                  </div>
                </CCol>
                <CCol md="6">
                  <div class="form-group">
                    <label class="form__label">{{ $lang.calendar.form.staff }}
                      <required_span/>
                    </label>
                    <v-select id="staff" :options="staffOptions" v-model="calendar.staff"></v-select>
                    <small class="error" v-if="$v.calendar.$error && !$v.calendar.staff.required">{{
                        $lang.calendar.validation.required.staff
                      }}</small>
                  </div>
                </CCol>
                <CCol md="6">
                  <div class="form-group">
                    <label class="form__label">{{ $lang.calendar.form.duration }} (Minutes)
                      <required_span/>
                    </label>
                    <input type="number" :placeholder="$lang.calendar.form.duration" class="form-control"
                           v-model="calendar.duration" readonly/>
                    <small class="error" v-if="$v.calendar.$error && !$v.calendar.duration.required">{{
                        $lang.calendar.validation.required.duration
                      }}</small>
                  </div>
                </CCol>
              </CRow>
              <hr>
              <h2>Break Time</h2>
              <div class="inputArea" v-for="input in inputs" :key="input.id">
                <CRow>
                  <CCol md="4">
                    <div class="form-group">
                      <label class="form__label">{{ $lang.calendar.form.startTime }}</label>
                      <input type="time" :id="'start_time'+input.id" :placeholder="$lang.calendar.form.startTime"
                             class="form-control" v-model="input.startTime"/>
                    </div>
                  </CCol>
                  <CCol md="4">
                    <div class="form-group">
                      <label class="form__label">{{ $lang.calendar.form.endTime }}</label>
                      <input type="time" :id="'end_time'+input.id" :placeholder="$lang.calendar.form.endTime"
                             class="form-control" v-model="input.endTime"/>
                    </div>
                  </CCol>
                  <CCol md="4">
                    <div class="form-group">
                      <label class="form__label">Actions</label><br>
                      <button type="button" class="btn btn-success" @click="addInput">
                        <i class="fa fa-plus"></i>
                      </button>&nbsp;
                      <button type="button" class="btn btn-danger" @click="subInput(input.id)">
                        <i class="fa fa-minus"></i>
                      </button>
                    </div>
                  </CCol>
                </CRow>
              </div>
              <hr>
              <h2>Days</h2>
              <CRow>
                <CCol md="12">
                  <div class="form-group">
                    <table class="table table-bordered">
                      <tr>
                        <th>Sunday</th>
                        <th>Monday</th>
                        <th>Tuesday</th>
                        <th>Wednesday</th>
                        <th>Thursday</th>
                        <th>Friday</th>
                        <th>Saturday</th>
                      </tr>
                      <tr>
                        <td><input type="checkbox" :value="0" v-model="calendar.day"/></td>
                        <td><input type="checkbox" :value="1" v-model="calendar.day"/></td>
                        <td><input type="checkbox" :value="2" v-model="calendar.day"/></td>
                        <td><input type="checkbox" :value="3" v-model="calendar.day"/></td>
                        <td><input type="checkbox" :value="4" v-model="calendar.day"/></td>
                        <td><input type="checkbox" :value="5" v-model="calendar.day"/></td>
                        <td><input type="checkbox" :value="6" v-model="calendar.day"/></td>
                      </tr>
                    </table>
                  </div>
                </CCol>
              </CRow>
              <CRow>
                <CCol col="6" class="text-left">
                  <CButton color="primary" :disabled="submitted" class="px-4" type="submit">
                    {{ $lang.common.general.submit }}
                  </CButton>
                </CCol>
              </CRow>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {calendar} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import {ServerTable} from "vue-tables-2";
import {required} from "vuelidate/lib/validators";
import required_span from "../../components/layouts/general/required-span";

Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);
export default {
  name: "CalendarItem",
  mixins: [Mixin],
  components: {
    required_span,
  },
  data() {
    return {
      counter: 0,
      inputs: [{
        id: 'break0',
        startTime: '',
        endTime: '',
      }],
      messageColor: "",
      submitted: false,
      SubmitBtn: "Submit",
      err_msg: "",
      alertMessage: "test",
      showAlert: false,
      dismissCountDown: 0,
      self: this,
      module: calendar,
      msg: "",
      staffOptions: [],
      calendar: {
        startDate: "",
        endDate: "",
        startTime: "",
        endTime: "",
        staff: [],
        day: [],
        duration: 30,
        // duration: "",
      },
    };
  },
  validations: {
    calendar: {
      startDate: {
        required,
      },
      endDate: {
        required,
      },
      startTime: {
        required,
      },
      endTime: {
        required,
      },
      staff: {
        required,
      },
      duration: {
        required,
      },
    },
  },
  mounted() {
    let self = this;
    store.commit("showLoader", false); // Loader Off
    self.dismissCountDown = 0;
    this.$root.$on("alert", (arg1, arg2) => {
    });
    if (localStorage.getItem("showAlert") !== "") {
      this.setAlert(true);
    }
    this.setAlert(false);
    localStorage.setItem("showAlert", "");
    axios.get('/calendar/verifier-staff/list').then((response) => {
      response.data.data.map(function (value, key) {
        self.staffOptions.push({value: value.id, label: value.name});
      });
    });
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    addInput() {
      this.inputs.push({
        id: `break${++this.counter}`,
        startTime: '',
        endTime: '',
      });
    },
    subInput(id) {
      let self = this;
      const myArr = self.inputs;
      const index = myArr.findIndex(function (o) {
        return o.id === id;
      });
      if (index !== 0) myArr.splice(index, 1);
    },
    onProfileSubmit() {
      let self = this;
      this.$v.calendar.$touch();
      const length = self.calendar.day.length;
      if (length === 0) {
        alert('Please select at least one day in between selected date range');
        return false;
      }
      if (this.$v.calendar.$invalid) {
        self.submitted = false; //Enable Button
        this.submitStatus = "ERROR";
      } else {
        let self = this;
        self.submitted = true; //Disable Button
        const postData = {
          "startDate": self.calendar.startDate,
          "endDate": self.calendar.endDate,
          "startTime": self.calendar.startTime,
          "endTime": self.calendar.endTime,
          "staff": self.calendar.staff.value,
          "duration": self.calendar.duration,
          "day": self.calendar.day,
          "breakTime": self.inputs,
        }
        axios
            .post(this.createUrlWeb(this.module), postData)
            .then((response) => {
              self.submitted = false; //Enable Button
              store.commit("showLoader", false); // Loader Off
              if (response.status == 200) {
                self.err_msg = response.data.message;
                localStorage.setItem("notificationType", "success");
                localStorage.setItem("notificationMessage", response.data.message);
                this.$router.push({ name: "Calendar" });
                //this.$router.go();
                self.messageColor = "success";
                self.dismissCountDown = 10;
              } else {
                self.err_msg = response.data.message;
                self.messageColor = "danger";
                self.dismissCountDown = 10;
              }
            })
            .catch(function () {
              self.dismissCountDown = 10;
              self.submitted = false; //Enable Button
            });
      }
    },
    setAlert(value) {
      this.showAlert = value;
    },
  },
};
</script>
<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
